/* eslint-disable @typescript-eslint/camelcase */
import get from 'lodash.get';
import {
  Site_Setting_Entity__About_Us,
  Site_Setting_Entity__About_UsConnection,
  Site_Setting_Entity__About_UsEdge,
} from '../../graphql/types';
import { AboutUsDataProps } from '@content/types/aboutUs';
import { FileImageSharp } from '@content/types/api';

export const transformAboutUs = (
  aboutUs?: Site_Setting_Entity__About_UsConnection | null
): AboutUsDataProps | {} => {
  const aboutUsLanguages = {};

  if (!aboutUs) {
    return {};
  }

  aboutUs.allSiteSettingEntityAboutUs.edges.forEach((edge: Site_Setting_Entity__About_UsEdge) => {
    const data = edge.node;

    if (!data) {
      return {};
    }

    aboutUsLanguages[data.langcode] = {
      aboutUs: {
        seo: {
          title: get(data, 'field_meta_title', ''),
          description: get(data, 'field_meta_description', ''),
          image: get(data, 'relationships.field_main_image.localFile.publicURL'),
        },
        hero: {
          title: get(data, 'field_hero_headline.value', ''),
          paragraph: get(data, 'field_hero_text.value', ''),
          image: get(data, 'relationships.field_hero_image.localFile', null) as FileImageSharp,
          imageAlt: get(data, 'field_hero_image.alt', ''),
          imageMobile: get(
            data,
            'relationships.field_hero_image_mobile.localFile',
            null
          ) as FileImageSharp,
          imageMobileAlt: get(data, 'field_hero_image_mobile.alt', ''),
        },
        bulletsTitle: get(data, 'field_pl_header_1.value', ''),
        bullets: get(
          data,
          'relationships.field_components[0].relationships.field_bullets_item',
          []
        ).map(section => {
          const image = get(section, 'relationships.field_image.localFile', null) as FileImageSharp;

          return {
            imageAlt: get(section, 'field_image.alt', ''),
            image,
            paragraph: get(section, 'field_text.value', ''),
            video: get(section, 'field_video', ''),
          };
        }),
        management: get(data, 'relationships.field_member_of_the_board_paragr', []).map(member => {
          const image = get(
            member,
            'relationships.field_photo_member_of_the_board.localFile',
            null
          ) as FileImageSharp;

          return {
            imageAlt: get(member, 'field_photo_member_of_the_board.alt', ''),
            image: image,
            paragraph: get(member, 'field_description_member_board.value', ''),
          };
        }),
        managementTitle: get(data, 'field_info_text.value', ''),
        additionalButton: {
          label: get(data, 'field_btn_au_text_1', ''),
          url: get(data, 'field_btn_au_url_1', ''),
        },
      },
    };
  }) as Site_Setting_Entity__About_Us[];

  return aboutUsLanguages;
};
