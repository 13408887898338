import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import actions from '@store/actions';
import AboutUsComponent, { AboutUsProps } from '@components/aboutUs';
import { RootState } from '@store/types';

const mapStateToProps = (state: RootState) => ({
  configurator: state.configurator,
  content: state.content,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  configuratorActions: bindActionCreators(actions.configurator, dispatch),
  contentActions: bindActionCreators(actions.content, dispatch),
});

interface DAboutUsPropsProps extends AboutUsProps {}
const DAboutUs: React.FC<DAboutUsPropsProps> = (props: DAboutUsPropsProps) => (
  <AboutUsComponent {...props} />
);

const AboutUsConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DAboutUs);

export default AboutUsConnected;
