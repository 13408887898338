import React from 'react';

import AboutUsContainer from '@containers/aboutUs';
import useAboutUsQuery from '@graphql/queries/AboutUsQuery';
import MetaPage from '@components/common/MetaPage';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';
import { PageContext } from '@content/types/pageContext';

interface AboutUsProps extends LocationDependent {
  pageContext: PageContext;
}

export default (props: AboutUsProps) => {
  const aboutUsQuery = useAboutUsQuery()[props.pageContext.langcode];

  return (
    <>
      <MetaPage
        title={aboutUsQuery.aboutUs.seo.title}
        description={aboutUsQuery.aboutUs.seo.description}
        image={aboutUsQuery.aboutUs.seo.image}
      />
      <DataLayer location={props.location} />
      <AboutUsContainer
        {...aboutUsQuery}
        langcode={props.pageContext.langcode}
        urls={props.pageContext.allUrls}
      />
    </>
  );
};
