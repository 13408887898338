/* eslint-disable @typescript-eslint/no-unused-vars */
import { graphql, useStaticQuery } from 'gatsby';
import * as transformAboutUs from '@content/transformers/AboutUsPageTransformers';
import { AboutUsDataProps } from '@content/types/aboutUs';

export interface AboutUsQueryProps {}

const AboutUsQuery = (): AboutUsQueryProps => {
  const aboutUsQuery = useStaticQuery(graphql`
    query {
      allSiteSettingEntityAboutUs {
        edges {
          node {
            langcode
            field_meta_description
            field_meta_title
            field_btn_au_text_1
            field_btn_au_url_1
            relationships {
              field_components {
                relationships {
                  field_bullets_item {
                    field_text {
                      value
                    }
                    field_image {
                      alt
                    }
                    field_video
                    relationships {
                      field_image {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 460, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              field_main_image {
                localFile {
                  publicURL
                }
              }
              field_hero_image {
                localFile {
                  childImageSharp {
                    fixed(width: 1600, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              field_hero_image_mobile {
                localFile {
                  childImageSharp {
                    fixed(width: 800, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              field_member_of_the_board_paragr {
                field_description_member_board {
                  value
                }
                field_photo_member_of_the_board {
                  alt
                }
                relationships {
                  field_photo_member_of_the_board {
                    localFile {
                      childImageSharp {
                        fixed(width: 143, quality: 100) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
            field_info_text {
              value
            }
            field_hero_image {
              alt
            }
            field_hero_image_mobile {
              alt
            }
            field_hero_text {
              value
            }
            field_hero_headline {
              value
            }
            field_pl_header_1 {
              value
            }
          }
        }
      }
    }
  `);

  return transformAboutUs.transformAboutUs(aboutUsQuery);
};

export default AboutUsQuery;
