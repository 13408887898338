import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import { AboutUsManagement } from '@content/types/aboutUs';
import Image from 'gatsby-image';
import * as utils from '@utils';

const AboutUsManagementComponentWrapper = styled.div`
  padding-top: 30px;

  ${respondFrom(
    breakpoints.md,
    css`
      padding-top: 0;
    `
  )}

  h2 {
    text-align: center;
  }
`;

const ManagementList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column;
  margin-top: 30px;
  margin-bottom: 50px;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-flow: row wrap;
      justify-content: center;
      margin-left: -7px;
      margin-right: -7px;
      margin-top: 60px;
    `
  )}

  li {
    margin: 10px 0;
    background-color: ${colors.grayNurse};
    padding: 30px 30px 40px;

    ${respondFrom(
      breakpoints.lg,
      css`
        margin: 0 7px 14px;
        padding: 30px 40px 70px;
        width: calc((100% / 2) - 14.5px);
        max-width: 450px;
      `
    )}
  }
`;

const ManagementImage = styled.div`
  margin-bottom: 30px;

  .gatsby-image-wrapper {
    margin: auto;
  }
`;

const ManagementParagraph = styled.div`
  text-align: center;

  h3 {
    text-transform: uppercase;
    margin: 0;
  }

  h6 {
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 25px;
  }
`;

interface AboutUsManagementProps {
  title: string;
  management: AboutUsManagement[];
}

const AboutUsManagementComponent = ({ title, management }: AboutUsManagementProps) => {
  return (
    <AboutUsManagementComponentWrapper>
      <h2>{utils.SafeHtml(title)}</h2>
      <ManagementList>
        {management.map((element, index) => (
          <li key={index}>
            <ManagementImage>
              {element.image && <Image {...element.image.childImageSharp} alt={element.imageAlt} />}
            </ManagementImage>
            <ManagementParagraph>{utils.SafeHtml(element.paragraph)}</ManagementParagraph>
          </li>
        ))}
      </ManagementList>
    </AboutUsManagementComponentWrapper>
  );
};

export default AboutUsManagementComponent;
