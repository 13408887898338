import React from 'react';
import styled from '@emotion/styled';

import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import AboutUsHero from '@components/aboutUs/AboutUsHero';
import AboutUsBulletsComponent from '@components/aboutUs/AboutUsBullets';
import AboutUsManagementComponent from '@components/aboutUs/AboutUsManagement';
import ButtonLink from '@components/common/ButtonLink';

const AdditionalButtonWrapper = styled.div`
  text-align: center;
`;

export interface AboutUsProps {
  langcode: string;
  urls: Array<string>;
}

export default props => {
  return (
    <Page>
      <AboutUsHero hero={props.aboutUs.hero} />
      <Container>
        <AboutUsBulletsComponent
          bullets={props.aboutUs.bullets}
          title={props.aboutUs.bulletsTitle}
          langcode={props.langcode}
          urls={props.urls}
        />
        {props.aboutUs.management.length > 0 ? (
          <AboutUsManagementComponent
            management={props.aboutUs.management}
            title={props.aboutUs.managementTitle}
          />
        ) : null}
        {props.aboutUs.additionalButton.url &&
        props.aboutUs.additionalButton.url !== '' &&
        props.aboutUs.additionalButton.label &&
        props.aboutUs.additionalButton.label !== '' ? (
          <AdditionalButtonWrapper>
            <ButtonLink to={props.aboutUs.additionalButton.url} icon="arrow-right">
              {props.aboutUs.additionalButton.label}
            </ButtonLink>
          </AdditionalButtonWrapper>
        ) : null}
      </Container>
    </Page>
  );
};
