import React from 'react';
import { AboutUsHero } from '@content/types/aboutUs';
import Hero from '@components/common/Hero';
import * as utils from '@utils';

interface AboutUsHeroProps {
  hero: AboutUsHero;
}

const AboutUsHeroComponent = (props: AboutUsHeroProps) => {
  return (
    <Hero
      size="medium"
      title={props.hero.title}
      image={props.hero.image ? props.hero.image.childImageSharp : undefined}
      imageMobile={props.hero.imageMobile ? props.hero.imageMobile.childImageSharp : undefined}
      buttonText={props.hero.button ? props.hero.button : ''}
      buttonRoute="/sklep"
      paragraph={utils.SafeHtml(props.hero.paragraph)}
      theme="dark"
    ></Hero>
  );
};

export default AboutUsHeroComponent;
