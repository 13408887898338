import React from 'react';
import styled from '@emotion/styled';
import BranchBullets from '@components/branches/sections/BranchBullets';
import { AboutUsBullets } from '@content/types/aboutUs';
import * as utils from '@utils';

const AboutUsBulletsComponentWrapper = styled.div`
  h2 {
    text-align: center;
    margin: 40px 0;
  }
`;

interface AboutUsBulletsProps {
  bullets: AboutUsBullets[];
  title: string;
  langcode: string;
  urls: Array<string>;
}

const AboutUsBulletsComponent = ({ bullets, title, langcode, urls }: AboutUsBulletsProps) => {
  return (
    <AboutUsBulletsComponentWrapper>
      <h2>{utils.SafeHtml(title)}</h2>
      <BranchBullets
        bullets={bullets}
        noLastDot={true}
        circles={[
          { position: 0, rotate: 180, size: 'big', side: 'center' },
          { position: 1, rotate: 90, size: 'big', side: 'center' },
          { position: 2, rotate: 0, size: 'big', side: 'center' },
        ]}
        aboutUsPage={true}
        langcode={langcode}
        urls={urls}
      />
    </AboutUsBulletsComponentWrapper>
  );
};

export default AboutUsBulletsComponent;
